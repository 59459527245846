import React, { useState, useEffect, useRef } from "react";
import { projects } from "../data-projects";
import { Link } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import VanillaTilt from "vanilla-tilt"; // https://micku7zu.github.io/vanilla-tilt.js/

export const ProjectCard = (props) => {
  const [loaded, setLoaded] = useState(0);

  if (!props.isSelected) {
    return <></>;
  }

  var projectLink = projects[props.project].projectLink;
  var cursor = "cursor-pointer";

  if (projects[props.project].projectTags.includes("locked")) {
    projectLink = "";
    cursor = "cursor-default";
  }

  const options = {
    scale: 1.04,
    transition: true,
    speed: 3000,
    max: 3,
    reverse: true,
  };

  return (
    <div className={"transition-opacity duration-700 opacity-" + loaded}>
      <Tilt options={options}>
        <Link to={projectLink + "#"}>
          <div className={"py-3 px-2 md:px-1 " + cursor}>
            <div className="bg-black hover:bg-white rounded-lg transition-all duration-300">
              <div className="flex relative transform filter transition-all duration-500 shadow-2xl drop-shadow-2xl opacity-100 hover:opacity-95">
                <img
                  alt={projects[props.project].projectName}
                  className="inset-0 w-full h-full object-cover object-center rounded-lg"
                  src={projects[props.project].teaserImage}
                  onLoad={() => setLoaded(100)}
                />
                <div className="absolute z-10 w-full h-full transition-all duration-500">
                  <div className="h-full text-sm sm:text-base flex flex-col justify-between">
                    <span className="flex flex-col px-3 py-2 rounded-lg title-font text-md font-medium text-white pb-16 w-full bg-gradient-to-b from-black to-transparent">
                      <span className="flex flex-row tracking-widest text-md title-font font-medium text-blue-300">
                        {projects[props.project].projectName}
                        {projectLink === "" && (
                          <div className="px-2 pt-1">
                            <InlineIcon icon="fa-solid:lock" />
                          </div>
                        )}
                      </span>
                      <span>{projects[props.project].tagline}</span>
                    </span>
                    {/* {projects[props.project].projectTags.includes("locked") && (
                      <div className="border-2">
                        UX Researcher embedded in cross-functional R&D team in
                        Dolby’s Technology Incubation Group. Pathfinding (vision
                        and strategy) for multi-modal user experiences (web,
                        mobile, audio) with distributed smart devices in the
                        home.
                      </div>
                    )} */}
                    <span className="text-sm h-full w-full absolute opacity-0 hover:opacity-100 transition-all duration-700">
                      <span className="m-1 px-2 py-1 font-medium text-green-400 bg-gray-900 bg-opacity-70 absolute bottom-0 rounded-md ">
                        {projects[props.project].skills}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      </Tilt>
    </div>
  );
};

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}
