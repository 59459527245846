import React, { useEffect, useState } from "react";
import { About } from "./components/About";
import { Bottombar } from "./components/Bottombar";
import { Navbar } from "./components/Navbar";
import { Projects } from "./components/Projects";
import { Project } from "./components/Project";
import { Research } from "./components/Research";
import { Routes, Route, useLocation } from "react-router-dom";
import { HiddenVideo } from "./components/HiddenVideo";
import { ThesisAppendix } from "./components/ThesisAppendix";
import "./App.css";

export default function App() {
  const [emphasis, setEmphasis] = useState(false);

  const mapEmphasis = {
    "www.robinson.audio": "audio",
    "robinson.audio": "audio",
    "www.fredericrobinson.ch": "ux",
    "fredericrobinson.ch": "ux",
    localhost: "localhost",
  };

  if (!emphasis) {
    setEmphasis(mapEmphasis[window.location.hostname]);

    // console.log("Hostname is " + window.location.hostname);
    // console.log("Emphasis on " + mapEmphasis[window.location.hostname]);
  }

  return (
    <main className="text-black bg-white body-font">
      <div className="overflow-x-hidden relative">
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="about" element={<About />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/:id" element={<Project />} />
          <Route path="research" element={<Research />} />
          <Route path="thesisappendix" element={<ThesisAppendix />} />
          <Route path=":id" element={<HiddenVideo />} />
        </Routes>
        <Bottombar emphasis={emphasis} />
      </div>
    </main>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
