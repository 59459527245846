import React, { useState, useEffect } from "react";
import { projects } from "../data-projects";
import { ProjectCard } from "./ProjectCard";
import { Helmet } from "react-helmet";

export const Projects = () => {
  const [tag, setTag] = useState("UX");

  // number of projects shown

  const [numberOfProjectsShown, setNumberOfProjectsShown] = useState(7);
  const [showMoreProjectButton, setShowMoreProjectButton] = useState(true);

  const handleClick = () => {
    setShowMoreProjectButton(false);
    setNumberOfProjectsShown(14);
  };

  // grab window size

  const [windowSize, setWindowSize] = useState(0);
  const [projectModulo, setProjectModulo] = useState(0);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    setWindowSize(getWindowSize());

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (windowSize.innerWidth < 768 && projectModulo === 0) {
    setProjectModulo(-1);
  } else if (windowSize.innerWidth >= 768 && projectModulo === -1) {
    setProjectModulo(0);
  }

  return (
    <>
      <Helmet>
        <title>Projects | Frederic Robinson</title>
        <meta name="title" content="Projects | Frederic Robinson" />
      </Helmet>
      {/* <div className="flex items-center flex-col w-full mb-6">
          <TagSelector tag={tag} setTag={setTag} />
        </div> */}
      <div className="flex justify-center">
        <div className="lg:px-2 flex flex-col md:flex-row max-w-screen-lg">
          <div className="flex flex-col md:flex-col md:flex-wrap justify-start px-3 lg:px-2 md:w-1/2">
            {Object.keys(projects).map((project) => (
              <div>
                {projects[project].order % 2 > projectModulo &&
                  projects[project].order <= numberOfProjectsShown && (
                    <ProjectCard
                      // isSelected={projects[project].projectTags.includes(tag)}
                      isSelected={true}
                      key={projects[project].teaserImage}
                      project={project}
                    />
                  )}
              </div>
            ))}
          </div>
          <div className="flex flex-col md:flex-col md:flex-wrap justify-start px-2 lg:px-2 md:w-1/2">
            {projectModulo === 0 &&
              Object.keys(projects).map((project) => (
                <div>
                  {projects[project].order % 2 === 0 &&
                    projects[project].order <= numberOfProjectsShown && (
                      <ProjectCard
                        // isSelected={projects[project].projectTags.includes(tag)}
                        isSelected={true}
                        key={projects[project].teaserImage}
                        project={project}
                      />
                    )}
                </div>
              ))}
          </div>
        </div>
      </div>
      {showMoreProjectButton && (
        <div className="flex justify-center pt-5">
          <div
            onClick={handleClick}
            className="text-white bg-blue-600 border-2 border-white cursor-pointer py-2 px-6 focus:outline-none 
            hover:bg-blue-500 transform hover:scale-105 rounded-lg text-lg transition-all duration-300"
          >
            More projects
          </div>
        </div>
      )}
    </>
  );
};

const TagSelector = (props) => {
  return (
    <div className="flex cursor-default md:flex-row">
      <div
        onClick={() => props.setTag("Installation")}
        className={`${
          props.tag === "Installation"
            ? "bg-green-600 hover:bg-green-600"
            : "bg-gray-600"
        } w-24 py-1 md:w-36 md:text-base text-center 
        text-white bg-gray-600  
        hover:bg-gray-700 text-sm border-gray-500 border-r-2 rounded-l-md`}
      >
        Installation
      </div>
      <div
        onClick={() => props.setTag("Interactive")}
        className={`${
          props.tag === "Interactive"
            ? "bg-green-600 hover:bg-green-600"
            : "bg-gray-600"
        } w-24 py-1 md:w-36 md:text-base text-center text-white bg-gray-600 focus:outline-none hover:bg-gray-700 text-sm border-gray-500 border-r-2`}
      >
        Interactive
      </div>
      <div
        onClick={() => props.setTag("All Projects")}
        className={`${
          props.tag === "All Projects"
            ? "bg-green-600 hover:bg-green-600"
            : "bg-gray-600"
        } w-24 py-1 md:w-36 md:text-base text-center text-white focus:outline-none hover:bg-gray-700 text-sm rounded-r-md`}
      >
        All Projects
      </div>
    </div>
  );
};

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

// sm	640px
// md	768px
// lg	1024px
// xl	1280px
