import { React } from "react";
// import { ArrowUpIcon } from "@heroicons/react/solid";
import { Icon } from "@iconify/react";

export const Bottombar = (props) => {
  return (
    <header className="top-0 z-10 pt-6 sm:pt-10">
      <div className="flex flex-wrap pb-2 flex-col items-center justify-between mt-6">
        <div className="w-72 pb-4 flex text-gray-600 items-center justify-evenly hover:text-gray-400">
          <a
            className="m-0.5 hover:text-blue-600 transition-all duration-200"
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/frederic-robinson"
          >
            <Icon icon="akar-icons:linkedin-fill" width="36px" />
          </a>
          {/* <a
            className="m-1 hover:text-blue-600 transition-all duration-200"
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/r00binson"
          >
            <Icon icon="fa-brands:twitter-square" width="30px" />
          </a> */}

          {/* {props.emphasis === "audio" && (
            <a
              className="m-1 hover:text-blue-600 transition-all duration-200"
              target="_blank"
              rel="noreferrer"
              href="https://soundcloud.com/fredericrobinson"
            >
              <Icon icon="icomoon-free:soundcloud2" width="30px" />
            </a>
          )} */}

          {/* {props.emphasis === "audio" && (
            <a
              className="m-1 hover:text-blue-600 transition-all duration-200"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/fredericrobinsonmusic"
            >
              <Icon icon="grommet-icons:facebook" width="30px" />
            </a>
          )}

          {props.emphasis === "audio" && (
            <a
              className="m-1 hover:text-blue-600 transition-all duration-200"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/mrfredrob"
            >
              <Icon icon="fa-brands:instagram-square" width="30px" />
            </a>
          )} */}

          <a
            className="m-1 pt-1 hover:text-blue-600 transition-all duration-200"
            target="_blank"
            rel="noreferrer"
            href="mailto:frederic@robinsonconsulting.ch"
          >
            <Icon icon="fa-solid:envelope" width="33px"/>
          </a>
        </div>
        {/* <a
          href="# "
          className="border-0 py-2 px-3 focus:outline-none 
          rounded text-base mt-4 text-gray-500"
        >
          <ArrowUpIcon className="w-5 h-5 mx-1 mb-2 hover:text-gray-700 transition-all duration-200" />
        </a> */}
        <div className="title-font font-medium text-gray-500 mb-8 ml-3 text-s">
          © 2024 • All Rights Reserved
        </div>
      </div>
    </header>
  );
};
