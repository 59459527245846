import React from "react";
import { useParams } from "react-router-dom";
import YouTube from "react-youtube-embed";

export const HiddenVideo = (props) => {
  let { id } = useParams();

  const ids = {
    debris: "QLmTN8rUn0A",
    audiocells: "dDzsPiUQiMc",
    "implicit-hri": "W8NMAAxh1mc",
    soundinhri: "soundinhri",
    distributedrobotsound: "Vdkmrg9v1FA",
  };

  if (ids[id] === undefined) {
    return <EmptyPage />;
  }

  if (ids[id] === "soundinhri") {
    return window.location.replace("https://r00binson.wixsite.com/soundinhri");
  }

  return (
    <div className="flex px-5 flex-row justify-center">
      <div className="flex-grow max-w-screen-lg">
        <div className="border-t my-6 w-full border-gray-700 " />
        <YouTube id={ids[id]} />
        <div className="border-t my-6 w-full border-gray-700 " />
      </div>
    </div>
  );
};

export const EmptyPage = () => {
  return (
    <div className="p-36">
      <div className="text-4xl w-full h-96 flex flex-col justify-center items-center">
        <div>Nothing to see here... 💔</div>
      </div>
    </div>
  );
};
