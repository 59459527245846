import React from "react";
import YouTube from "react-youtube-embed";

import { Helmet } from "react-helmet";

export const ThesisAppendix = (props) => {
  return (
    <>
      <Helmet>
        <title>Digital Thesis Appendix | Frederic Robinson</title>
        <meta
          name="title"
          content="Digital Thesis Appendix | Frederic Robinson"
        />
      </Helmet>
      <div className="mx-auto flex flex-col items-centder justify-between max-w-screen-lg">
        <h1 className="justify-between w-full title-font text-2xl md:text-3xl mb-6 font-medium">
          Designing Sound for Social Robots: <br />
          Advancing Professional Practice through Design Principles
        </h1>

        <div className="w-full max-w-screen-lg flex flex-row ml-2 mb-10">
          <div className="max-w-screen-md w-full">
            3-Minute Research Synopsis
            <YouTube id={"nK2t9y6G8VA"} />
            <a
              className="text-blue-700 hover:underline"
              href="https://www.universitiesaustralia.edu.au/media-item/wired-for-sound-friendly-robots-project-wins-high-profile-research-communications-challenge/"
            >
              Universities Australia Vice Chancellors' Award
            </a>{" "}
            - 1st place science communication competition
          </div>
        </div>

        <h1 className="justify-between w-full title-font text-2xl md:text-3xl mb-10 font-medium">
          Digital Thesis Appendix
        </h1>

        <p className="lg:w-full leading-relaxed title-font text-lg font-medium text-black mb-10">
          This digital appendix contains supplementary materials for Chapters 4
          through 8. These include survey data, interview transcripts, and
          documentation of creative work. All chapters have been published and
          the materials are publicly available.
          <br /> <br />
          <a className="text-blue-700 hover:underline" href="#chapter4">
            Chapter 4 - Designing Sound for Social Robots: Candidate Design
            Principles
          </a>{" "}
          <br />
          <a className="text-blue-700 hover:underline" href="#chapter5">
            Chapter 5 - Smooth Operator: Tuning Robot Perception Through
            Artificial Movement Sound
          </a>{" "}
          <br />
          <a className="text-blue-700 hover:underline" href="#chapter6">
            Chapter 6 - Crafting the Language of Robotic Agents: A Vision for
            Electroacoustic Music in Human-Robot Interaction
          </a>{" "}
          <br />
          <a className="text-blue-700 hover:underline" href="#chapter7">
            Chapter 7 - Robot Sound in Distributed Audio Environments
          </a>{" "}
          <br />
          <a className="text-blue-700 hover:underline" href="#chapter8">
            Chapter 8 - Spatially Distributed Robot Sound: A Case Study
          </a>{" "}
          <br />
        </p>

        {/* <div className="w-full max-w-screen-lg flex flex-row justify-end mb-14">
          <div className="max-w-screen-md w-full">
            Visual Thesis Abstract
            <YouTube id={"nK2t9y6G8VA"} />
          </div>
        </div> */}

        {/* ------------------------------------------------------------------------------ */}

        <h2
          id="chapter4"
          className="justify-between w-full title-font text-1xl md:text-2xl mb-6 font-medium"
        >
          Chapter 4 - Designing Sound for Social Robots: Candidate Design
          Principles
        </h2>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <b>Abstract:</b> <br />
          How can we use sound and music to create rich and engaging human-robot
          interactions? A growing body of HRI research explores the many ways in
          which sound affects human-robot interactions and although some studies
          conclude with tentative design recommendations, there are, to our
          knowledge, no generalised design recommendations for the robot sound
          design process. We address this gap by first investigating sound
          design frameworks in the domains of product sound design and film
          sound to see whether practices and concepts from these areas contain
          actionable insights for the creation of robot sound. We then present
          three case studies, detailed examinations of the sound design of
          commercial social robots Cozmo and Vector, Jibo, and Kuri, facilitated
          by expert interviews with the robots' sound designers. Combining
          insights from the design frameworks and case studies, we propose nine
          candidate design principles for robot sound which provide 1) a
          design-oriented perspective on robot sound that may inform future
          research, and 2) actionable guidelines for designers, engineers and
          decision-makers aiming to use sound to create richer and more refined
          human-robot interactions.
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://link.springer.com/article/10.1007/s12369-022-00891-0"
            >
              Link to publication
            </a>
            &nbsp;- International Journal of Social Robotics
          </span>
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify mb-10">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://static-content.springer.com/esm/art%3A10.1007%2Fs12369-022-00891-0/MediaObjects/12369_2022_891_MOESM1_ESM.pdf"
            >
              Download Link
            </a>
            &nbsp;- Transcripts of expert interviews
          </span>
        </p>

        {/* ------------------------------------------------------------------------------ */}

        <h2
          id="chapter5"
          className="justify-between w-full title-font text-1xl md:text-2xl mb-6 font-medium"
        >
          Chapter 5 - Smooth Operator: Tuning Robot Perception Through
          Artificial Movement Sound
        </h2>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <b>Abstract:</b> <br />
          Can we influence how a robot is perceived by designing the sound of
          its movement? Drawing from practices in film sound, we overlaid a
          video depicting a robot's movement routine with three types of
          artificial movement sound. In a between-subject study design,
          participants saw either one of the three designs or a quiet control
          condition and rated the robot's movement quality, safety, capability,
          and attractiveness. We found that, compared to our control, the sound
          designs both increased and decreased perceived movement quality.
          Coupling the same robotic movement with different sounds lead to the
          motions being rated as more or less precise, elegant, jerky, or
          uncontrolled, among others. We further found that the sound conditions
          decreased perceived safety, and did not affect perceived capability
          and attractiveness. More unrealistic sound conditions led to larger
          differences in ratings, while the subtle addition of harmonic material
          was not rated differently to the control condition in any of the
          measures. Based on these findings, we discuss the challenges and
          opportunities regarding the use of artificial movement sound as an
          implicit channel of communication that may eventually be able to
          selectively target specific characteristics, helping designers in
          creating more refined and nuanced human-robot interactions.
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://dl.acm.org/doi/10.1145/3434073.3444658"
            >
              Link to publication
            </a>
            &nbsp;- Association for Computing Machinery
          </span>
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://unsw.au1.qualtrics.com/jfe/form/SV_86Tn7dfp8WOdAyN"
            >
              Link to Survey
            </a>
            &nbsp;- The survey for participants
          </span>
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://dl.acm.org/action/downloadSupplement?doi=10.1145%2F3434073.3444658&file=hrifp1113aux.zip"
            >
              Download Link
            </a>
            &nbsp;- Dataset and R commands
          </span>
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify mb-2"></p>

        <div className="w-full max-w-screen-lg flex flex-row ml-2 mb-14">
          <div className="max-w-screen-md w-full">
            Video footage with the four sound conditions
            <YouTube id={"isdAfENgPk8"} />
          </div>
        </div>

        {/* ------------------------------------------------------------------------------ */}

        <h2
          id="chapter6"
          className="justify-between w-full title-font text-1xl md:text-2xl mb-6 font-medium"
        >
          Chapter 6 - Crafting the Language of Robotic Agents: A Vision for
          Electroacoustic Music in Human-Robot Interaction
        </h2>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <b>Abstract:</b> <br />
          This article discusses the role of electroacoustic music practice in
          the context of human-robot interaction (HRI), illustrated by the first
          author's work creating the sonic language of interactive robotic
          artwork Diamandini. It starts with a discussion of the role of sound
          in social robotics and surveys various notable conceptual approaches
          to robot sound. The central thesis of the article is that
          electroacoustic music can provide a valuable source of aesthetic
          considerations and creative practice for the creation of richer and
          more refined sonic HRIs by giving practitioners new ways to create
          believable sounding objects, to convey fiction, agency and animacy,
          and to communicate causality in auditory feedback. To demonstrate
          this, the authors describe the rationale for treating robot sound
          design as a compositional process and discuss the implications of the
          endeavour's non-linear and site- specific nature. These considerations
          are illustrated using sound examples and design decisions made
          throughout the creation process of the robotic artwork. The authors
          conclude with observations on how the compositional process is
          affected by this particular application context.
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify mb-4">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://www.cambridge.org/core/journals/organised-sound/article/abs/crafting-the-language-of-robotic-agents-a-vision-for-electroacoustic-music-in-humanrobot-interaction/3551E61DC3B8CE0550EFE21D31917521"
            >
              Link to publication
            </a>
            &nbsp;- Cambridge University Press
          </span>
        </p>

        <div className="w-full max-w-screen-lg flex flex-row ml-2 mb-14">
          <div className="max-w-screen-md w-full">
            Sound Example 1
            <audio controls className="w-full mb-6">
              {/* <source src="horse.ogg" type="audio/ogg" /> */}
              <source src="thesis/Sound Example 1.mp3" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            Video Example 1
            <YouTube id={"XvC3nXzOIvs"} className="mb-6" />
            Video Example 2
            <YouTube id={"wJwtY_-BCkg"} />
          </div>
        </div>

        {/* ------------------------------------------------------------------------------ */}

        <h2
          id="chapter7"
          className="justify-between w-full title-font text-1xl md:text-2xl mb-6 font-medium"
        >
          Chapter 7 - Robot Sound in Distributed Audio Environments
        </h2>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <b>Abstract:</b> <br />
          While a robot's ability to listen spatially has been an active field
          of research for a while, robots emitting spatial sound have received
          significantly less attention. With the growing availability of
          audio-enabled networked devices in the home, facilitated by the
          Internet of Things, future homes will likely feature distributed audio
          environments, and robots will be able to emit sound across these. In
          this chapter, we therefore ask, how a robot's auditory communication
          might be enhanced by being distributed across loudspeakers in the
          environment. To explore this question, we first conduct interviews
          with researchers and practitioners with distributed audio and sound
          installation experience, identifying design themes for applying
          interactive sound installation techniques in the context of
          human-robot interaction. These insights, combined with the authors'
          own expertise in interactive immersive audio environments, then inform
          the creation of a virtual distributed robot sound prototype, a process
          which includes the ideation and realisation of spatial robot sound.
          After presenting the prototype and its design process, we reflect on
          lessons learnt and propose a generalised design framework for spatial
          robot sound. We argue that spatial robot sound can be broken down into
          three key locations - the robot, objects of interest in the
          environment, and the space itself - and that transitions between these
          locations create spatial relationships which are relevant to HRI
          scenarios.
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify mb-4">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://www.routledge.com/Sound-and-Robotics-Speech-Non-verbal-audio-and-Robotic-Musicianship/Savery/p/book/9781032340838"
            >
              Link to publication
            </a>
            &nbsp;- Routledge
          </span>
        </p>

        <div className="w-full max-w-screen-lg flex flex-row ml-2 mb-14">
          <div className="max-w-screen-md w-full">
            Video Excerpt 1
            <YouTube id={"Vdkmrg9v1FA"} />
          </div>
        </div>

        {/* ------------------------------------------------------------------------------ */}

        <h2
          id="chapter8"
          className="justify-between w-full title-font text-1xl md:text-2xl mb-6 font-medium"
        >
          Chapter 8 - Spatially Distributed Robot Sound: A Case Study
        </h2>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify">
          <b>Abstract:</b> <br />
          The potential of spatial sound to immerse, guide, and affect humans is
          well-known, but has so far received little attention in the field of
          human-robot interaction. In this paper, we therefore explore how a
          robot emitting spatial sound affects a human's impressions and
          behavior. Following a Research-through-Design (RtD) approach, we
          created two immersive robot sound designs, which are emitted across
          the robot's body and through speakers in the environment. In an
          evaluation study, participants interacted with the robot and shared
          their impressions through semi-structured interviews. Reactions showed
          that spatial robot sound had a notable effect on participant behavior
          and impressions, influencing, among others, how much attention they
          paid to the physical robot and how much animacy and agency they
          attributed to it. We report on our insights into how spatial sound may
          provide social robots with new ways to inform and engage the humans
          around them.
        </p>

        <p className="p-2 leading-relaxed text-gray-900 max-w-screen-md text-justify mb-4">
          <span>
            <a
              target="_blank"
              className="text-blue-700 hover:underline"
              href="https://dl.acm.org/doi/10.1145/3563657.3596095"
            >
              Link to publication
            </a>
            &nbsp;- Association for Computing Machinery
          </span>
        </p>

        <div className="w-full max-w-screen-lg flex flex-row ml-2 mb-14">
          <div className="max-w-screen-md w-full">
            Case Study Documentation:
            <YouTube id={"h5XKVfxPG_8"} />
          </div>
        </div>
      </div>
    </>
  );
};
