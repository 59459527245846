import React, { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { projects } from "../data-projects";
import YouTube from "react-youtube-embed";
import ImageGallery from "react-image-gallery";
import { EmptyPage } from "./HiddenVideo";
import { Helmet } from "react-helmet";
import "./test.css";

export const Project = (props) => {
  let { id } = useParams();

  const project = projects[id];

  if (project === undefined) {
    return <EmptyPage />;
  }

  return (
    <>
      <Helmet>
        <title>{project.projectName} | Frederic Robinson</title>
        <meta
          name="title"
          content={project.projectName + " | Frederic Robinson"}
        />
      </Helmet>
      <div
        style={{ whiteSpace: "pre-wrap" }}
        className="flex flex-col items-center"
      >
        <div className="px-5 lg:flex-grow max-w-screen-lg flex flex-col md:items-center md:text-left items-center">
          <h1 className="flex flex-col md:flex-row justify-between w-full title-font sm:text-4xl text-3xl mb-8 font-medium">
            <div>{project.projectName}</div>
            <div className="text-lg mt-4 md:mt-0">{project.year}</div>
          </h1>
          <div className="flex flex-col justify-between lg:flex-row items-center lg:items-start w-full mb-8">
            <p className="lg:w-1/2 leading-relaxed text-gray-900">
              {project.shortDescription}
            </p>
            <div>
              {project.role && <Role role={project.role} />}
              {project.tech && <Tech tech={project.tech} />}
            </div>
          </div>

          <YouTube id={project.videoID} />
          <div className="mb-8" />

          {project.longDescription && (
            <p className="leading-relaxed text-gray-900 mb-8">
              {project.longDescription}
            </p>
          )}

          {project.videoID2 && <YouTube id={project.videoID2} />}
          {project.videoID2 && <div className="mb-10" />}

          {project.pictureGallery && (
            <PictureGallery folder={project.pictureGallery} />
            // evtl this https://www.npmjs.com/package/react-photo-gallery/v/8.0.0
          )}
          {project.videoID2 && <div className="mb-8" />}

          {project.credits && <Credits credits={project.credits} />}

          {project.recognition && (
            <Recognition recognition={project.recognition} />
          )}
        </div>
      </div>
    </>
  );
};

// const PictureRow = (props) => {
//   const [loaded, setLoaded] = useState(100);

//   const SingleImage = () => {
//     var index = Math.floor(Math.random() * 6) + 1;
//     console.log(index);

//     const images = ["portrait.jpeg", "meta.jpeg", "google-scholar.png"];

//     return (
//       <div className="flex-grow w-1/3">
//         <img
//           className={
//             "flex-grow border-2 p-2 h-72 rounded-lg object-cover transition-all duration-200 opacity-" +
//             loaded
//           }
//           alt="Portrait"
//           src={"/projects/mof17/mof17-gallery/" + index + ".jpeg"}
//           onLoad={() => setLoaded(100)}
//         />
//       </div>
//     );
//   };

//   const rows = [];
//   for (let i = 0; i < 6; i++) {
//     // note: we are adding a key prop here to allow react to uniquely identify each
//     // element in this array. see: https://reactjs.org/docs/lists-and-keys.html
//     rows.push(<SingleImage key={i} />);
//   }

//   return (
//     <div className="flex flex-row w-full flex-wrap justify-evenly items-stretch">
//       {props.text}

//       {rows}
//     </div>
//   );
// };

const PictureGallery = (props) => {
  var images = [];

  for (let i = 1; i <= props.folder.pictures; i++) {
    images.push({ original: props.folder.path + "/" + i + ".jpeg" });
  }

  // const customStyle = {
  //   width: "100%",
  //   height: "auto",
  //   maxHeight: "80vh",
  //   objectFit: "cover",
  //   overflow: "hidden",
  //   objectPosition: "center center",
  // };

  return (
    <div className="mb-2">
      <ImageGallery
        useBrowserFullscreen={false}
        lazyLoad={true}
        showThumbnails={false}
        showPlayButton={false}
        items={images}
      />
    </div>
  );
};

const Role = (props) => {
  return (
    <div className="mt-4 lg:mt-1 text-left h-full w-80 px-3 py-1.5 leading-relaxed text-gray-900 border-gray-700 rounded border-2 text-sm">
      <div className="text-lg pb-2">{"Role"}</div>
      <div>{props.role}</div>
    </div>
  );
};

const Tech = (props) => {
  return (
    <div className="mt-4 lg:mt-1 text-left h-full w-80 px-3 py-1.5 leading-relaxed text-gray-900 border-gray-700 rounded border-2 text-sm">
      <div className="text-lg pb-2">{"Tech"}</div>
      <div>{props.tech}</div>
    </div>
  );
};

const Credits = (props) => {
  if (!props.credits) {
    return <></>;
  }
  return (
    <div className="text-left h-full w-full px-3 py-1.5 leading-relaxed text-gray-900 border-gray-700 rounded border-2">
      <div className="text-lg pb-2">{"Credits"}</div>
      {props.credits}
    </div>
  );
};

const Recognition = (props) => {
  if (!props.recognition) {
    return <></>;
  }

  return (
    <div className="text-left h-full w-full px-3 py-1.5 leading-relaxed text-gray-900 border-gray-700 rounded border-2">
      <div className="text-lg pb-2">{"Recognition"}</div>
      {props.recognition}
    </div>
  );
};
